<template>
  <div class="mt-1 mx-3">
    <broker-statement> </broker-statement>
  </div>
</template>

<script>
import BrokerStatement from "@/components/log/broker-statement/index.vue";
import { mapActions } from "vuex";
export default {
  props: {
    msg: String,
  },
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  components: {
    BrokerStatement,
  },
  created() {
    this.notifyQueue({
      text: "Rendiconti",
      path: "broker_statement",
      level: 1,
    });
  },
};
</script>
