<template>
  <div class="mt-1 mx-3">
    <sms> </sms>
  </div>
</template>

<script>
import Sms from '@/components/log/sms/index.vue';
import { mapActions } from 'vuex';
export default {
  props: {
    msg: String,
  },
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  components: {
    Sms,
  },
   created() {
    this.notifyQueue({ text: "Sms", path: "module.LOG.LSMS", level: 1 });
  },
};
</script>