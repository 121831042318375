<template>
  <div class="mt-1 mx-3">
    <hybrid-mail> </hybrid-mail>
  </div>
</template>

<script>
import HybridMail from '@/components/log/hybrid-mail/index.vue';
import { mapActions } from 'vuex';
export default {
  props: {
    msg: String,
  },
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  components: {
    HybridMail,
  },
   created() {
    this.notifyQueue({ text: "Mail Ibrida", path: "module.LOG.LIBR", level: 1 });
  },
};
</script>