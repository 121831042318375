<template>
  <div class="mt-1 mx-3">
    <email> </email>
  </div>
</template>

<script>
import Email from "@/components/log/email/index.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    msg: String,
  },
  methods: {
    ...mapActions("queue", ["notifyQueue", "emptyQueue"]),
    ...mapActions("breadcrumbs", { removeBCS: "remove" }),
    ...mapGetters("breadcrumbs", { loadBCS: "loadBreadcrumbs" }),
  },
  components: {
    Email,
  },
  created() {
    this.emptyQueue();
    this.notifyQueue({
      level: 0,
      path: "module.HOME",
      text: "HOME",
    });
    this.notifyQueue({
      level: 1,
      path: "",
      text: "Log",
    });
    this.notifyQueue({
      level: 2,
      path: "",
      text: "Email",
    });
  },
};
</script>
