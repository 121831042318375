<template>
  <div>
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <b-form @submit.prevent="onSearch(filterName)">
          <b-card class="filter">
            <b-row>
              <div class="col-md-3">
                <base-select
                  name="code"
                  label="Rifiutato"
                  v-model="filter.byAttribute.is_error"
                  :options="[
                    { value: 'Y', text: 'Si' },
                    { value: 'N', text: 'No' },
                  ]"
                />
              </div>
              <div class="form-group col-md-4 align-self-end">
                <b-button type="submit" variant="lisaweb" size="sm"
                  >Cerca</b-button
                >
                <b-button
                  class="btn-reset"
                  type="button"
                  variant="lisaweb"
                  size="sm"
                  @click="onClearFilter(filterName)"
                  >Reset</b-button
                >
              </div>
            </b-row>
          </b-card>
        </b-form>
        <import-details
          :fields="fields"
          :repository="repository"
          :resource="resource"
          :filterName="filterName"
          :ref="tableRef"
          :filterOn="{
            byImportLog: {
              id: dbRowData.import_logs.length
                ? dbRowData.import_logs[0].id
                : 0,
            },
          }"
          noInnerWidth
        ></import-details>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import BaseIcon from "@/components/BaseIcon";
import BaseSelect from "@/components/form/BaseSelect";
import { getDictionary } from "@/utils/dictionary";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";

export default {
  mixins: [CustomFiltersMixin],
  name: "General",
  props: {
    resourceId: Number,
    dbRowData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      filter: this.initFilter(),
      filterName: "filterImportGeneralDetails",
      isLoading: false,
      repository: "import_detail",
      resource: "import_details",
      tableRef: "ImportGeneralDetailsTableRef",
      args: {},
      fields: [
        {
          key: "detail",
          label: this.getDictionary("details", "import_log"),
        },
        {
          key: "code",
          label: this.getDictionary("code", "import_detail"),
        },
        {
          key: "title",
          label: this.getDictionary("title", "import_detail"),
        },
        {
          key: "is_error.text",
          label: this.getDictionary("is_error", "import_detail"),
        },
      ],
    };
  },
  components: {
    ImportDetails: () => import("@/components/tables/ImportDetails"),
    BaseIcon,
    BaseSelect,
  },
  methods: {
    getDictionary,
    initFilter() {
      let init = {
        byAttribute: {
          code: null,
          is_error: null,
        },
      };
      return init;
    },
    onSearch(name) {
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      this.removePaginationByName(name);
      this.$refs[this.tableRef].fetch();
    },
    onClearFilter(name) {
      this.resetFilter();
      this.resetPagination();
      this.removeFilterByName(name);
      this.removePaginationByName(name);
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      this.$refs[this.tableRef].sortBy = "";
      this.$refs[this.tableRef].sortDesc = false;
      this.onSearch(name);
    },
    onSaveFilter(name, criteria) {
      this.saveFilterByName({ name, criteria });
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
  },
};
</script>
